/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import Image from "../components/Image";
import {graphql, Link} from 'gatsby'
import Layout from "../components/layout"
import "./events.css";
import {Chip, Grid} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {germanDateToEnglishDate, getState} from "../components/utils";
import SEO from "../components/SEO";
import Banner from "../components/Banner";
import * as moment from "moment";

export default class Events extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedType: props.location.state ? props.location.state.type : null,
            selectedCategory: props.location.state ? props.location.state.category : null
        };
    }

    // executed after building
    componentDidMount() {
        if(window) {
            const params = getState(window.location.href);

            if (!params) return;

            this.setState({
                selectedType: params.type,
                selectedCategory: params.category
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.state && prevProps.location.state &&
            (this.props.location.state.type !== prevProps.location.state.type ||
                this.props.location.state.category !== prevProps.location.state.category)) {
            if(window) {
                const params = getState(window.location.href);

                if(!params) {
                    return;
                }

                this.setState({
                    selectedType: params.type,
                    selectedCategory: params.category
                });
            }
        }
    }

    isNewEvent(event) {
        const today = moment();
        let compareDate;
        if(event.enddate) {
            compareDate = moment(event.enddate, 'DD/MM/YYYY');
        } else if(event.startdate){
            compareDate = moment(event.startdate, 'DD/MM/YYYY');
        } else {
            // no dates provided
            return true;
        }
        return compareDate.isSameOrAfter(today);
    }

    isShowEvent(event) {
        if(!this.isNewEvent(event)) {
            return false;
        }

        if(!this.state.selectedCategory && !this.state.selectedType) {
            return true;
        }

        if(!this.state.selectedType) {
            return event.category === this.state.selectedCategory
        }

        if(!this.state.selectedCategory) {
            return event.type === this.state.selectedType
        }

        return event.type === this.state.selectedType && event.category === this.state.selectedCategory;
    }

    render() {
        const types = Array.from(new Set(this.props.data.allWordpressWpEvent.edges.map(edge => edge.node.acf.event.type)));
        const categories = Array.from(new Set(this.props.data.allWordpressWpEvent.edges.map(edge => edge.node.acf.event.category)));

        return <Layout id="events">
            <SEO title={this.props.data.wordpressPage.yoast_meta.yoast_wpseo_title}
                 description={this.props.data.wordpressPage.yoast_meta.yoast_wpseo_metadesc}
            />
            {
                this.props.data.wordpressPage.acf.banner &&
                <Banner marginTop={0}
                        title={this.props.data.wordpressPage.acf.banner.title}
                        description={this.props.data.wordpressPage.acf.banner.description}
                        image={this.props.data.wordpressPage.acf.banner.image}
                />
            }

            <div className='content'>
                <Grid container  type="flex" justify="start" style={{marginBottom: '2em'}}>
                    <Grid item xl={6} lg={8} md={12} xs={12}>
                        {this.props.data.wordpressPage.acf.events.content}
                    </Grid>
                </Grid>

                <Grid container  type="flex" justify="start" style={{marginBottom: '2em'}}>
                    <Grid item lg={4} md={12} xs={12}>
                        <Autocomplete options={types}
                                      getOptionLabel={type => type}
                                      value={this.state.selectedType}
                                      onChange={(event, values) => {
                                          this.setState({selectedType: values});
                                      }}
                                      renderInput={params => (
                                          <TextField {...params} label="Typ" variant="outlined" fullWidth />
                                      )}
                                      style={{marginBottom: '2em'}}
                        />
                    </Grid>
                    <Grid item lg={4} md={12} xs={12}>
                        <Autocomplete options={categories}
                                      getOptionLabel={category => category}
                                      value={this.state.selectedCategory}
                                      onChange={(event, values) => {
                                          this.setState({selectedCategory: values});
                                      }}
                                      renderInput={params => (
                                          <TextField {...params} label="Kategorie" variant="outlined" fullWidth />
                                      )}
                                      style={{marginBottom: '2em'}}
                        />
                    </Grid>
                </Grid>

                <Grid container type="flex" justify="start" spacing={2}>
                    {
                        this.props.data.allWordpressWpEvent.edges
                            .sort((a, b) => {
                                const date = new Date();

                                const today = germanDateToEnglishDate(`${date.getDate()}.${date.getMonth() < 10 ? 0 : ''}${date.getMonth()}.${date.getFullYear()}`);
                                const aDate = a.node.acf.event.startdate ? germanDateToEnglishDate(a.node.acf.event.startdate) : today;
                                const bDate = b.node.acf.event.startdate ? germanDateToEnglishDate(b.node.acf.event.startdate) : today;

                                return (aDate >= bDate) ? 1 : -1;
                            })
                            .map((edge, index) => {
                                if(this.isShowEvent(edge.node.acf.event)) {
                                    return <Grid item lg={4} md={12} style={{marginBottom: '2em'}} key={`event_becometrainer_relaqua_${index}`} spacing={2}>
                                        <Link to={`/event/${edge.node.slug}`}>
                                            <Card className="card">
                                                {
                                                    edge.node.acf.event.image && <Image image={edge.node.acf.event.image} />
                                                }
                                                <CardContent style={{padding: '2em'}}>
                                                    <h3 className='title-big'>
                                                        {edge.node.acf.event.title}
                                                    </h3>
                                                    <div style={{marginBottom: '1em'}}>
                                                        {edge.node.acf.event.level && <Chip style={{marginRight: '0.5em'}} color="secondary" label={edge.node.acf.event.level}/>}
                                                        {edge.node.acf.event.instructor && <Chip label={`Instructor: ${edge.node.acf.event.instructor}`}/>}
                                                    </div>
                                                    <div>
                                                        {edge.node.acf.event.shortdescription}
                                                    </div>
                                                    <div style={{marginTop: '1em'}}>
                                                        <strong>Datum: </strong>
                                                        {
                                                            edge.node.acf.event.enddate ?
                                                                <span> {edge.node.acf.event.startdate} - {edge.node.acf.event.enddate}</span>
                                                                :
                                                                <span>{edge.node.acf.event.startdate}</span>
                                                        }
                                                    </div>
                                                    <div><strong>Ort: </strong> {edge.node.acf.event.location.location}</div>
                                                    {edge.node.acf.event.price && <div><strong>Preis: </strong> {edge.node.acf.event.price}</div>}
                                                    <div><strong>Kategorie: </strong>{edge.node.acf.event.category}</div>
                                                    <div></div>
                                                </CardContent>
                                            </Card>
                                        </Link>
                                    </Grid>
                                }
                                return "";
                            })
                    }
                </Grid>
            </div>
        </Layout>
    }
}

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
            }
            acf {
                events {
                    content
                }
                banner {
                    title
                    description
                    image {
                        alt_text
                        title
                        description
                        localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
            }
        }
        allWordpressWpEvent {
            edges {
                node {
                    id
                    slug
                    acf {
                        event {
                            level
                            instructor
                            category
                            shortdescription
                            longdescription
                            enddate
                            location {
                                location
                                street
                                postleitzahl
                            }
                            price
                            title
                            startdate
                            type
                            image {
                                alt_text
                                title
                                description
                                localFile {
                                    childImageSharp {
                                        id
                                        fluid {
                                            base64
                                            tracedSVG
                                            aspectRatio
                                            src
                                            srcSet
                                            srcWebp
                                            srcSetWebp
                                            originalName
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

`;


