import React from 'react'
import "./banner.css";
import Image from "../components/Image";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {Link} from "gatsby";
import {OutboundLink} from "gatsby-plugin-google-analytics"

export default function Banner({title, image, description, customStyles, link, linktext, externlink}) {
    return (
        <div className="page-banner" style={customStyles}>
            <div className="image">
                {image && image.localFile && <Image image={image}/>}
            </div>
            <Grid container direction="column" justify="center" className="content">
                <Grid item className="title">
                    <h1 className='title-big'>{title}</h1>
                </Grid>
                <Grid item className="description">
                    {description}

                </Grid>
                <Grid item style={{textAlign: 'center'}}>
                    {link && !externlink && <Link to={link}>
                        <Button variant="contained" key={<slide className="title" />} size="small" color="primary">
                            {linktext}
                        </Button>
                    </Link>}

                    {link && externlink && <OutboundLink href={link}>
                        <Button variant="contained" key={<slide className="title" />} size="small" color="primary">
                            {linktext}
                        </Button>
                    </OutboundLink>}
                </Grid>

            </Grid>
        </div>
    )
};